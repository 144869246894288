import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select, Tabs, Drawer, DatePicker, InputNumber, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import dot from 'dot-object';
import MediaSelect from './components/mediaSelect.field';
import EntityMedia from './entity.media.page';
import { API_CDN_URL } from '../services/config';
import { changeToDayJsObject, changeToDateString, changeToDateUTC, changeToDateTimeUTC } from '../helpers/date';
import EntityDiscussionService from '../services/entity.discussion.service';
import { gold, green } from '@ant-design/colors';
import {
    readTrip,
    createTrip,
    updateTrip
} from '../actions/entity.trip';
import {
    readAllActiveTripCountriesTranslations
} from '../actions/entity.trip.country';
import {
    readAllActiveTripTypesTranslations
} from '../actions/entity.trip.type';
import {
    readAllActiveTripTagsTranslations
} from '../actions/entity.trip.tag';
import {
    readAllActiveTripTopicsTranslations
} from '../actions/entity.trip.topic';
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import SeoFields from './components/seo.component';
import Editor from './components/editor.field';
import ReferenceSelect from './components/referenceSelect.field';
import { readAllActiveTripHolidaysTranslations } from '../actions/entity.trip.holiday';
import slugify from '../helpers/field';
import { readEntity } from '../actions/entity';
import TextEditor from '../components/TextField';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};
function convertTripData(data) {
    for (let k in data.languages) {
        let lang = data.languages[k];
        if (typeof data[lang] !== 'undefined') {
            if (typeof data[lang].tripAddons !== 'undefined') {
                data[lang].tripAddons = data[lang].tripAddons.map(addon => {
                    if (addon.type === 'array') {
                        addon.options = addon.options.map(option => {
                            return [option.name, option.code, option.price, option.unitPrice, option.status ? "true" : "false"].join(", ");
                        }).join('\n');
                    }
                    return addon;
                });
            }
            if (typeof data[lang].addons !== 'undefined') {
                data[lang].addons = data[lang].addons.map(addon => {
                    if (addon.type === 'array') {
                        addon.options = addon.options.map(option => {
                            return [option.name, option.code, option.price, option.status ? "true" : "false"].join(", ");
                        }).join('\n');
                    }
                    return addon;
                });
            }
        }
    }
    return data;
}
function convertTripDatesToUTC(data) {
    for (let k in data.languages) {
        let lang = data.languages[k];
        if (typeof data[lang] !== 'undefined') {
            if (typeof data[lang].itineraries !== 'undefined' && data[lang].itineraries !== null && Array.isArray(data[lang].itineraries)) {
                for (let i in data[lang].itineraries) {
                    if (typeof data[lang].itineraries[i].date !== 'undefined' && data[lang].itineraries[i].date !== null) {
                        data[lang].itineraries[i].date = changeToDateUTC(data[lang].itineraries[i].date);
                    } else {
                        data[lang].itineraries[i].date = null;
                    }
                }
            }
        }
    }
    if (typeof data.checkout_end_date !== 'undefined' && data.checkout_end_date !== null) {
        data.checkout_end_date = changeToDateTimeUTC(data.checkout_end_date);
    } else {
        data.checkout_end_date = null;
    }
    if (typeof data.checkout_start_date !== 'undefined' && data.checkout_start_date !== null) {
        data.checkout_start_date = changeToDateTimeUTC(data.checkout_start_date);
    } else {
        data.checkout_start_date = null;
    }
    if (typeof data.end_date !== 'undefined' && data.end_date !== null) {
        data.end_date = data.end_date.hour(0).minute(0).second(0).millisecond(0);
        data.end_date = changeToDateUTC(data.end_date);
    } else {
        data.end_date = null;
    }
    if (typeof data.start_date !== 'undefined' && data.start_date !== null) {
        data.start_date = data.start_date.hour(0).minute(0).second(0).millisecond(0);
        data.start_date = changeToDateUTC(data.start_date);
    } else {
        data.start_date = null;
    }
    if (typeof data.early_bird_start_date !== 'undefined' && data.early_bird_start_date !== null) {
        data.early_bird_start_date = changeToDateTimeUTC(data.early_bird_start_date);
    } else {
        data.early_bird_start_date = null;
    }
    if (typeof data.early_bird_end_date !== 'undefined' && data.early_bird_end_date !== null) {
        data.early_bird_end_date = changeToDateTimeUTC(data.early_bird_end_date);
    } else {
        data.early_bird_end_date = null;
    }
    return data;
}
function convertTripDatesToString(data) {
    for (let k in data.languages) {
        let lang = data.languages[k];
        if (typeof data[lang] !== 'undefined') {
            if (typeof data[lang].itineraries !== 'undefined' && data[lang].itineraries !== null && Array.isArray(data[lang].itineraries)) {
                for (let i in data[lang].itineraries) {
                    if (typeof data[lang].itineraries[i].date !== 'undefined' && data[lang].itineraries[i].date !== null) {
                        data[lang].itineraries[i].date = changeToDateString(data[lang].itineraries[i].date);
                    } else {
                        data[lang].itineraries[i].date = null;
                    }
                }
            }
        }
    }
    if (typeof data.checkout_end_date !== 'undefined' && data.checkout_end_date !== null) {
        data.checkout_end_date = changeToDateString(data.checkout_end_date);
    } else {
        data.checkout_end_date = null;
    }
    if (typeof data.checkout_start_date !== 'undefined' && data.checkout_start_date !== null) {
        data.checkout_start_date = changeToDateString(data.checkout_start_date);
    } else {
        data.checkout_start_date = null;
    }
    if (typeof data.end_date !== 'undefined' && data.end_date !== null) {
        data.end_date = changeToDateString(data.end_date);
    } else {
        data.end_date = null;
    }
    if (typeof data.start_date !== 'undefined' && data.start_date !== null) {
        data.start_date = changeToDateString(data.start_date);
    } else {
        data.start_date = null;
    }
    if (typeof data.early_bird_start_date !== 'undefined' && data.early_bird_start_date !== null) {
        data.early_bird_start_date = changeToDateString(data.early_bird_start_date);
    } else {
        data.early_bird_start_date = null;
    }
    if (typeof data.early_bird_end_date !== 'undefined' && data.early_bird_end_date !== null) {
        data.early_bird_end_date = changeToDateString(data.early_bird_end_date);
    } else {
        data.early_bird_end_date = null;
    }
    return data;
}
function convertTripDates(data) {
    for (let k in data.languages) {
        let lang = data.languages[k];
        if (typeof data[lang] !== 'undefined') {
            if (typeof data[lang].itineraries !== 'undefined' && data[lang].itineraries !== null && Array.isArray(data[lang].itineraries)) {
                for (let i in data[lang].itineraries) {
                    if (typeof data[lang].itineraries[i].date !== 'undefined' && data[lang].itineraries[i].date !== null) {
                        data[lang].itineraries[i].date = changeToDayJsObject(data[lang].itineraries[i].date);
                    } else {
                        data[lang].itineraries[i].date = null;
                    }
                }
            }
        }
    }
    if (typeof data.checkout_start_date !== 'undefined' && data.checkout_start_date !== null) {
        data.checkout_start_date = changeToDayJsObject(data.checkout_start_date, 'checkout_start_date');
    } else {
        data.checkout_start_date = null;
    }
    if (typeof data.checkout_end_date !== 'undefined' && data.checkout_end_date !== null) {
        data.checkout_end_date = changeToDayJsObject(data.checkout_end_date, 'checkout_end_date');
    } else {
        data.checkout_end_date = null;
    }
    if (typeof data.end_date !== 'undefined' && data.end_date !== null) {
        data.end_date = changeToDayJsObject(data.end_date, 'end_date');
    } else {
        data.end_date = null;
    }
    if (typeof data.start_date !== 'undefined' && data.start_date !== null) {
        data.start_date = changeToDayJsObject(data.start_date, 'start_date');
    } else {
        data.start_date = null;
    }
    if (typeof data.early_bird_start_date !== 'undefined' && data.early_bird_start_date !== null) {
        data.early_bird_start_date = changeToDayJsObject(data.early_bird_start_date);
    } else {
        data.early_bird_start_date = null;
    }
    if (typeof data.early_bird_end_date !== 'undefined' && data.early_bird_end_date !== null) {
        data.early_bird_end_date = changeToDayJsObject(data.early_bird_end_date);
    } else {
        data.early_bird_end_date = null;
    }
    return data;
}

function Trip() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [tripTitle, setTripTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const requiredTripSupportAllLanguages = currentEntity && currentEntity.requiredTripSupportAllLanguages;
    const [addedLanguages, setAddedLanguages] = useState([]);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);
    const [tripData, setTripData] = useState(null);
    const [tripTypeOptions, setTripTypeOptions] = useState([]);
    const [tripTagOptions, setTripTagOptions] = useState([]);
    const [tripTopicOptions, setTripTopicOptions] = useState([]);
    const [tripCountryOptions, setTripCountryOptions] = useState([]);
    const [tripHolidayOptions, setTripHolidayOptions] = useState([]);
    const [doubleOccupancyStatus, setDoubleOccupancyStatus] = useState(false);
    const [earlyBirdStatus, setEarlyBirdStatus] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState(null);

    useEffect(() => {
        dispatch(readEntity(params.entityId)).then((response) => {
            const url = response.result.protocol + '://' + response.result.host + (response.result.path ? `/${response.result.path}` : '') + '/zh';
            setWebsiteUrl(url);
        });
    }, [dispatch, params.entityId]);

    const earlyBirdStatusChange = (checked) => {
        setEarlyBirdStatus(checked);
    }

    const doubleOccupancyStatusChange = (checked) => {
        setDoubleOccupancyStatus(checked);
    }

    const showMediaDrawer = (fieldName) => {
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };
    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        if (currentMediaFieldName && currentEntity) {
            let url = API_CDN_URL(currentEntity) + media.path;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            let formValues = form.getFieldsValue();
            formValues = convertTripDatesToString(formValues);
            // Use dot to update partial values
            newValue = dot.dot(newValue);
            formValues = dot.dot(formValues);
            newValue = { ...formValues, ...newValue };
            newValue = dot.object(newValue);
            newValue = convertTripDates(newValue);
            form.setFieldsValue(newValue);
        }
    }

    useEffect(() => {
        if (currentEntity) {
            if (currentEntity.requiredTripSupportAllLanguages) {
                setAddedLanguages(currentEntity.languages.filter(language => {
                    return language.status
                }).map(language => {
                    return {
                        label: language.name,
                        value: language.code
                    }
                }));
            } else {
                if (tripData && typeof tripData.languages !== 'undefined' && Array.isArray(tripData.languages)) {
                    setAddedLanguages(currentEntity.languages.filter(language => {
                        return language.status && tripData.languages.includes(language.code)
                    }).map(language => {
                        return {
                            label: language.name,
                            value: language.code
                        }
                    }));
                }
            }

            // Fetch the trip types
            dispatch(readAllActiveTripTypesTranslations(currentEntity.id)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setTripTypeOptions(response.result.map(typeTranslation => {
                        return {
                            label: typeTranslation.name,
                            value: typeTranslation.id,
                            language: typeTranslation.language
                        }
                    }));
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            });
            // Fetch the trip tags
            dispatch(readAllActiveTripTagsTranslations(currentEntity.id)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setTripTagOptions(response.result.map(tagTranslation => {
                        return {
                            label: tagTranslation.name,
                            value: tagTranslation.id,
                            language: tagTranslation.language
                        }
                    }));
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            });
            // Fetch the trip topics
            dispatch(readAllActiveTripTopicsTranslations(currentEntity.id)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setTripTopicOptions(response.result.map(topicTranslation => {
                        return {
                            label: topicTranslation.title,
                            value: topicTranslation.id,
                            language: topicTranslation.language
                        }
                    }));
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            });
            // Fetch the trip holidays
            dispatch(readAllActiveTripHolidaysTranslations(currentEntity.id)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setTripHolidayOptions(response.result.map(holidayTranslation => {
                        return {
                            label: holidayTranslation.name,
                            value: holidayTranslation.id,
                            language: holidayTranslation.language
                        }
                    }));
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            });
            // Fetch the trip countries
            dispatch(readAllActiveTripCountriesTranslations(currentEntity.id)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setTripCountryOptions(response.result.map(countryTranslation => {
                        return {
                            label: countryTranslation.name,
                            value: countryTranslation.id,
                            language: countryTranslation.language
                        }
                    }));
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            });
        }
    }, [currentEntity, tripData]);
    const languageOptions = !requiredTripSupportAllLanguages && currentEntity && currentEntity.languages ? currentEntity.languages.map(language => {
        return {
            label: language.name,
            value: language.code,
            disabled: !language.status
        }
    }) : [];

    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('trip.message.read-only'));
            return;
        }
        if (typeof params.tripId !== 'undefined') {
            try {
                values = convertTripDatesToUTC(values);
            } catch (e) {
                console.log(e);
            }
            // Update the existing entity trip
            dispatch(updateTrip(params.entityId, params.tripId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setTripTitle(`${t('trip.title.edit')} <${response.result.slug}> (ID: ${response.result.id})`);
                    if (typeof response.result.early_bird_status !== 'undefined') {
                        if (response.result.early_bird_status) {
                            setEarlyBirdStatus(response.result.early_bird_status);
                        }
                    }
                    response.result = convertTripDates(response.result);
                    response.result = convertTripData(response.result);
                    form.setFieldsValue(response.result);
                    setTripData(response.result);
                }
                message.success(t('trip.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createTrip(params.entityId, values)).then((data) => {
                message.success(t('trip.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/trip/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/trips`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        if (typeof params.tripId !== 'undefined') {
            setTripTitle(t('trip.title.edit'));
            dispatch(readTrip(params.entityId, params.tripId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setTripTitle(`${t('trip.title.edit')} <${response.result.slug}> (ID: ${response.result.id})`);
                    if (typeof response.result.early_bird_status !== 'undefined') {
                        if (response.result.early_bird_status) {
                            setEarlyBirdStatus(response.result.early_bird_status);
                        }
                    }
                    response.result = convertTripDates(response.result);
                    response.result = convertTripData(response.result);
                    form.setFieldsValue(response.result);
                    setTripData(response.result);
                } else {
                    message.error(t('trip.message.error-reading'));
                    navigate(`/entity/${params.entityId}/trips`);
                }
            }).catch(e => {
                message.error(t('trip.message.error-reading'));
                navigate(`/entity/${params.entityId}/trips`);
            });
        } else {
            setTripTitle(t('trip.title.new'));
        }
    }, [params.tripId, params.entityId, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    const selectedLanguages = (options) => {
        if (Array.isArray(options)) {
            const filteredLanguages = languageOptions.filter(language => {
                if (options.includes(language.value)) {
                    return true;
                } else {
                    return false;
                }
            });
            setAddedLanguages(filteredLanguages);
        } else {
            setAddedLanguages([]);
        }
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/trips`}>{t('trip.actions.cancel')}</Button>
    ];
    if (websiteUrl && params.tripId && tripData && tripData.slug) {
        extraButtons.push(<Button style={{ backgroundColor: green[5] }} key="preview" type="primary" href={websiteUrl + '/trip/' + tripData.slug} target="_blank">{t('trip.actions.preview')}</Button>);
    }
    if (tripData && tripData.id) {
        extraButtons.push(<Button key="orders" type="primary" style={{ backgroundColor: gold[5] }} href={`/entity/${params.entityId}/orders/?trip=${tripData.id}`}>{t('trips.actions.orders')}</Button>);
    }
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.tripId ? t('trip.actions.update') : t('trip.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-trip"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            status: true,
                            visibility: 'public',
                            tour_people: 1,
                            add_to_cart_minimum_people: 1,
                            add_to_cart_maximum_people: 9999,
                            double_occupancy_price_discount: 0,
                            early_bird_status: false,
                            double_occupancy_status: false
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/trips`)}
                            title={tripTitle}
                            extra={extraButtons}
                        />
                        <Tabs>
                            <Tabs.TabPane tab={t('trip.tabs.general')} key="general" forceRender>
                                <h1>{t('trip.headings.general')}</h1>
                                {!requiredTripSupportAllLanguages && <Form.Item
                                    label={t('trip.field.languages.label')}
                                    required={true}
                                >
                                    <Form.Item
                                        name="languages"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.languages.required')
                                            }
                                        ]}
                                        noStyle>
                                        <Select mode="multiple" allowClear options={languageOptions} onChange={selectedLanguages} />
                                    </Form.Item>
                                    <div>{t('trip.field.languages.note')}</div>
                                </Form.Item>}
                                <Form.Item
                                    label={t('trip.field.slug.label')}
                                    required={true}
                                >
                                    <Form.Item
                                        name="slug"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.slug.required')
                                            },
                                            { pattern: /^[a-z0-9-_]+$/, message: t('trip.field.slug.pattern') }
                                        ]}
                                        noStyle>
                                        <Input placeholder={t('trip.field.slug.placeholder')} />
                                    </Form.Item>
                                    <div>{t('trip.field.slug.note')}</div>
                                </Form.Item>
                                <Form.Item
                                    label={t('trip.field.status.label')}
                                    name="status"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                                <Form.Item
                                    label={t('trip.field.confirmed.label')}
                                    name="confirmed"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                                <Form.Item
                                    label={t('trip.field.visibility.label')}
                                    name="visibility"
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('trip.field.visibility.required')
                                        }
                                    ]}
                                >
                                    <Select>
                                        <Select.Option value="public">{t('trip.field.visibility.public')}</Select.Option>
                                        <Select.Option value="private">{t('trip.field.visibility.private')}</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={t('trip.field.period.label')}
                                    required={true}
                                >
                                    <Form.Item
                                        name="start_date"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.start_date.required')
                                            }
                                        ]}>
                                        <DatePicker disabledTime={true} style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.start_date.placeholder')} />
                                    </Form.Item>
                                    <span style={{ display: 'inline-block', width: 40, textAlign: 'center' }}> - </span>
                                    <Form.Item
                                        name="end_date"
                                        noStyle
                                        dependencies={['start_date']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.end_date.required')
                                            },
                                            {
                                                validator: (rule, value) => {
                                                    if (value && value.isBefore(form.getFieldValue('start_date'))) {
                                                        return Promise.reject(t('trip.field.end_date.validator'));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            }
                                        ]}>
                                        <DatePicker disabledTime={true} style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.end_date.placeholder')} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    name="tour_people"
                                    label={t('trip.field.tour_people.label')}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('trip.field.tour_people.required')
                                        }
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} min={1} />
                                </Form.Item>
                                <Form.Item
                                    name="tour_maximum_people"
                                    label={t('trip.field.tour_maximum_people.label')}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('trip.field.tour_maximum_people.required')
                                        }
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} min={1} />
                                </Form.Item>
                                <Form.Item
                                    label={t('trip.field.checkout_period.label')}
                                    required={true}
                                >
                                    <Form.Item
                                        name="checkout_start_date"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.checkout_start_date.required')
                                            }
                                        ]}>
                                        <DatePicker showTime style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.checkout_start_date.placeholder')} />
                                    </Form.Item>
                                    <span style={{ display: 'inline-block', width: 40, textAlign: 'center' }}> - </span>
                                    <Form.Item
                                        name="checkout_end_date"
                                        noStyle
                                        dependencies={['checkout_start_date']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.checkout_end_date.required')
                                            },
                                            {
                                                validator: (rule, value) => {
                                                    if (value && value.isBefore(form.getFieldValue('checkout_start_date'))) {
                                                        return Promise.reject(t('trip.field.checkout_end_date.validator'));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            }
                                        ]}>
                                        <DatePicker showTime style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.checkout_end_date.placeholder')} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label={t('trip.field.add_to_cart_people.label')}
                                    required={true}
                                >
                                    <Form.Item
                                        name="add_to_cart_minimum_people"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.add_to_cart_minimum_people.required')
                                            }
                                        ]}>
                                        <InputNumber style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.add_to_cart_minimum_people.placeholder')} />
                                    </Form.Item>
                                    <span style={{ display: 'inline-block', width: 40, textAlign: 'center' }}> - </span>
                                    <Form.Item
                                        name="add_to_cart_maximum_people"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.add_to_cart_maximum_people.required')
                                            }
                                        ]}>
                                        <InputNumber style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.add_to_cart_maximum_people.placeholder')} />
                                    </Form.Item>
                                </Form.Item>
                                <h1>{t('trip.headings.general-earlybird')}</h1>
                                <Form.Item
                                    label={t('trip.field.earlybird_status.label')}
                                    name="early_bird_status"
                                    valuePropName="checked"
                                >
                                    <Switch onChange={earlyBirdStatusChange} />
                                </Form.Item>
                                {
                                    earlyBirdStatus && <Form.Item
                                        label={t('trip.field.earlybird_period.label')}
                                        required={true}
                                    >
                                        <Form.Item
                                            name="early_bird_start_date"
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('trip.field.early_bird_start_date.required')
                                                }
                                            ]}>
                                            <DatePicker showTime style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.early_bird_start_date.placeholder')} />
                                        </Form.Item>
                                        <span style={{ display: 'inline-block', width: 40, textAlign: 'center' }}> - </span>
                                        <Form.Item
                                            name="early_bird_end_date"
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('trip.field.early_bird_end_date.required')
                                                }
                                            ]}>
                                            <DatePicker showTime style={{ width: 'calc(50% - 20px)' }} placeholder={t('trip.field.early_bird_end_date.placeholder')} />
                                        </Form.Item>
                                    </Form.Item>
                                }
                                <h1>{t('trip.headings.general-double')}</h1>
                                <Form.Item
                                    label={t('trip.field.double_occupancy_status.label')}
                                    name="double_occupancy_status"
                                    valuePropName="checked"
                                >
                                    <Switch onChange={doubleOccupancyStatusChange} />
                                </Form.Item>
                                {
                                    doubleOccupancyStatus && <Form.Item
                                        name="double_occupancy_price_discount"
                                        label={t('trip.field.double_occupancy_price_discount.label')}
                                        required={true}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('trip.field.double_occupancy_price_discount.required')
                                            }
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                }
                                <h1>{t('trip.headings.general-pricing')}</h1>
                                {
                                    earlyBirdStatus && <Form.Item
                                        name="early_bird_price"
                                        label={t('trip.field.early_bird_price.label')}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder={t('trip.field.early_bird_price.placeholder')} />
                                    </Form.Item>
                                }
                                <Form.Item
                                    name="price"
                                    label={t('trip.field.price.label')}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('trip.field.price.required')
                                        }
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder={t('trip.field.price.placeholder')} />
                                </Form.Item>
                                <Form.Item
                                    name="deposit"
                                    label={t('trip.field.deposit.label')}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('trip.field.deposit.required')
                                        }
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder={t('trip.field.deposit.placeholder')} />
                                </Form.Item>
                                {addedLanguages.length > 0 ? <Tabs>
                                    {addedLanguages.map((language, index) => {
                                        return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                            <Form.Item
                                                label={t('trip.field.name.label')}
                                                name={[language.value, 'name']}
                                                required={true}
                                                rules={
                                                    [
                                                        {
                                                            required: true,
                                                            message: t('trip.field.name.required')

                                                        }
                                                    ]}
                                            >
                                                <Input placeholder={t('trip.field.name.placeholder')} />
                                            </Form.Item>
                                            <Form.Item
                                                label={t('trip.field.tag.label')}
                                                name={[language.value, 'tag']}
                                            >
                                                <Input placeholder={t('trip.field.tag.placeholder')} />
                                            </Form.Item>
                                            <Form.Item
                                                label={t('trip.field.tagColor.label')}
                                                name={[language.value, 'tagColor']}
                                            >
                                                <Input placeholder={t('trip.field.tagColor.placeholder')} />
                                            </Form.Item>
                                            <ReferenceSelect
                                                isMultiple={true}
                                                selectProps={{
                                                    showSearch: true,
                                                    allowClear: true,
                                                    labelInValue: true
                                                }}
                                                onFetch={(name) => EntityDiscussionService.searchDiscussion(params.entityId, name)}
                                                inner={{
                                                    name: [language.value, 'discussions']
                                                }}
                                                wrapper={{
                                                    label: t('trip.field.discussions.label'),
                                                }} />
                                            <Form.Item
                                                label={t('trip.field.start_location.label')}
                                                name={[language.value, 'start_location']}
                                            >
                                                <Input placeholder={t('trip.field.start_location.placeholder')} />
                                            </Form.Item>
                                            <Form.Item

                                                label={t('trip.field.end_location.label')}
                                                name={[language.value, 'end_location']}
                                            >
                                                <Input placeholder={t('trip.field.end_location.placeholder')} />
                                            </Form.Item>
                                            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                wrapperCol: 16,
                                                label: t('trip.field.banner.label')
                                            }} inner={{
                                                name: [language.value, 'banner'],
                                            }} />
                                            <Form.List name={[language.value, "images"]} style={{ width: '100%' }}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        <h1>{t('trip.field.images.label')}</h1>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                                    wrapperCol: 16,
                                                                    required: true,
                                                                    style: { marginBottom: 8, width: 'calc(100% - 100px)' }
                                                                }} inner={{
                                                                    name: [name],
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: t('trip.field.images.required')
                                                                        }
                                                                    ]
                                                                }}
                                                                    fieldName={[language.value, "images", name]}
                                                                    placeholder={t('trip.field.images.placeholder')} />
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.images.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                            <Form.List name={[language.value, "imageSlider"]} style={{ width: '100%' }}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        <h1>{t('trip.field.imageSlider.label')}</h1>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={24}>
                                                                            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                                                wrapperCol: 16,
                                                                                required: true,
                                                                                style: { marginBottom: 8, width: 'calc(100% - 100px)' }
                                                                            }} inner={{
                                                                                name: [name, 'path'],
                                                                                rules: [
                                                                                    {
                                                                                        required: true,
                                                                                        message: t('trip.field.images.required')
                                                                                    }
                                                                                ]
                                                                            }}
                                                                                fieldName={[language.value, "imageSlider", name, "path"]}
                                                                                placeholder={t('trip.field.images.placeholder')} />
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                name={[name, 'caption']}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                            >
                                                                                <Input placeholder={t('trip.field.images.caption.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.images.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                            <Form.List name={[language.value, "blocks"]} style={{ width: '100%' }}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        <h1>{t('trip.field.blocks.label')}</h1>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                name={[name, 'title']}
                                                                                wrapperCol={{ span: 24 }}
                                                                            >
                                                                                <Input placeholder={t('trip.field.blocks.title.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                name={[name, 'content']}
                                                                                wrapperCol={{ span: 24 }}
                                                                            >
                                                                                <Input placeholder={t('trip.field.blocks.content.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                name={[name, 'summary']}
                                                                                wrapperCol={{ span: 24 }}
                                                                            >
                                                                                <Input placeholder={t('trip.field.blocks.summary.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.blocks.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                            <h1>{t('trip.headings.relationship')}</h1>
                                            <Form.Item
                                                label={t('trip.field.countries.label')}
                                                name={[language.value, 'countries']}
                                            >
                                                <Select mode="multiple" allowClear options={tripCountryOptions.filter(translation => {
                                                    if (translation.language === language.value) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })} />
                                            </Form.Item>
                                            <Form.Item
                                                label={t('trip.field.holidays.label')}
                                                name={[language.value, 'holidays']}
                                            >
                                                <Select mode="multiple" allowClear options={tripHolidayOptions.filter(translation => {
                                                    if (translation.language === language.value) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })} />
                                            </Form.Item>
                                            <Form.Item
                                                label={t('trip.field.tags.label')}
                                                name={[language.value, 'tags']}
                                            >
                                                <Select mode="multiple" allowClear options={tripTagOptions.filter(translation => {
                                                    if (translation.language === language.value) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })} />
                                            </Form.Item>
                                            <Form.Item
                                                label={t('trip.field.topics.label')}
                                                name={[language.value, 'topics']}
                                            >
                                                <Select mode="multiple" allowClear options={tripTopicOptions.filter(translation => {
                                                    if (translation.language === language.value) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })} />
                                            </Form.Item>
                                            <Form.Item
                                                label={t('trip.field.types.label')}
                                                name={[language.value, 'types']}
                                            >
                                                <Select mode="multiple" allowClear options={tripTypeOptions.filter(translation => {
                                                    if (translation.language === language.value) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })} />
                                            </Form.Item>
                                            <SeoFields
                                                name={[language.value]}
                                                layout={layout}
                                                form={form}
                                                showMediaDrawer={showMediaDrawer}
                                            />
                                        </Tabs.TabPane>
                                    })}
                                </Tabs> : null}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('trip.tabs.intro')} key="intro" forceRender>
                                {addedLanguages.length > 0 ? <Tabs>
                                    {addedLanguages.map((language, index) => {
                                        return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                            <Form.Item
                                                label={t('trip.field.summary.label')}
                                                name={[language.value, 'summary']}
                                            >
                                                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.description.placeholder')} />
                                            </Form.Item>
                                            <Editor
                                                label={t('trip.field.description.label')}
                                                language={language.value}
                                                name={[language.value, 'description']}
                                                showMediaDrawer={showMediaDrawer}
                                                form={form}
                                            />
                                            <h1>{t('trip.headings.itinerary')}</h1>
                                            <Form.List name={[language.value, "itineraries"]} style={{ width: '100%' }}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div className="trip-itinerary-block" key={key} style={{ display: 'flex', width: '100%' }} align="baseline">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'name']}
                                                                                label={t('trip.field.itinerary.name.label')}
                                                                                rules={[{ required: true, message: t('trip.field.itinerary.name.required') }]}
                                                                            >
                                                                                <Input placeholder={t('trip.field.itinerary.name.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'status']}
                                                                                label={t('trip.field.itinerary.status.label')}
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Switch />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'title']}
                                                                                label={t('trip.field.itinerary.title.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.itinerary.title.required') }]}
                                                                            >
                                                                                <Input placeholder={t('trip.field.itinerary.title.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'date']}
                                                                                label={t('trip.field.itinerary.date.label')}
                                                                            >
                                                                                <DatePicker disabledTime={true} style={{ width: '100%' }} placeholder={t('trip.field.itinerary.date.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                        labelCol={{ span: 24 }}
                                                                        wrapperCol={{ span: 24 }}
                                                                        name={[name, 'description']}
                                                                        label={t('trip.field.itinerary.description.label')}
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.itinerary.description.placeholder')} />
                                                                    </Form.Item>
                                                                    <Form.List name={[name, "subItems"]} style={{ width: '100%' }}>
                                                                        {(subFields, { add: addSub, remove: removeSub, move: moveSub }) => (
                                                                            <>
                                                                                <div><strong>{t('trip.field.itinerary.subItems.label')}</strong></div>
                                                                                {subFields.map(({ key: subKey, name: subName, ...restSubField }, subFieldPosition) => {
                                                                                    return <div key={subKey} style={{ display: 'flex', width: '100%', marginBottom: 10, padding: 20, borderRadius: 20, background: 'rgba(141, 141, 141, 0.30)' }} align="baseline">
                                                                                        <Row gutter={[16, 0]} style={{ width: 'calc(100% - 100px)' }}>
                                                                                            <Col span={24}>
                                                                                                <Form.Item
                                                                                                    {...restSubField}
                                                                                                    style={{ marginBottom: 8, width: '100%' }}
                                                                                                    labelCol={{ span: 24 }}
                                                                                                    wrapperCol={{ span: 24 }}
                                                                                                    name={[subName, 'title']}
                                                                                                    label={t('trip.field.itinerary.subItems.title.label')}
                                                                                                    required={true}
                                                                                                    rules={[{ required: true, message: t('trip.field.itinerary.subItems.title.required') }]}
                                                                                                >
                                                                                                    <Input placeholder={t('trip.field.itinerary.subItems.title.placeholder')} />
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                            <Col span={24}>
                                                                                                <Form.Item
                                                                                                    {...restSubField}
                                                                                                    style={{ marginBottom: 8, width: '100%' }}
                                                                                                    labelCol={{ span: 24 }}
                                                                                                    wrapperCol={{ span: 24 }}
                                                                                                    name={[subName, 'description']}
                                                                                                    label={t('trip.field.itinerary.subItems.description.label')}
                                                                                                >
                                                                                                    <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.itinerary.subItems.description.placeholder')} />
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                            <Col span={24}>
                                                                                                <Form.List name={[subName, "images"]} style={{ width: '100%' }}>
                                                                                                    {(subSubFields, { add: addSubSub, remove: removeSubSub, move: moveSubSub }) => (
                                                                                                        <>
                                                                                                            <div><strong>{t('trip.field.itinerary.subItems.images.label')}</strong></div>
                                                                                                            {subSubFields.map(({ key: subSubKey, name: subSubName, ...restSubSubField }, subSubFieldPosition) => {
                                                                                                                return <div key={subSubKey} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                                                                    <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                                                                                        wrapperCol: 16,
                                                                                                                        required: true,
                                                                                                                        style: { marginBottom: 8, width: 'calc(100% - 100px)' }
                                                                                                                    }} inner={{
                                                                                                                        name: [subSubName],
                                                                                                                        rules: [
                                                                                                                            {
                                                                                                                                required: true,
                                                                                                                                message: t('trip.field.itinerary.subItems.images.required')
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }}
                                                                                                                        fieldName={[language.value, "itineraries", name, "subItems", subName, "images", subSubName]}
                                                                                                                        placeholder={t('trip.field.itinerary.subItems.images.placeholder')} />
                                                                                                                    <div style={{ width: 100 }}>
                                                                                                                        {(subSubFields.length > 1 && subSubFieldPosition !== 0) && <UpCircleOutlined onClick={() => moveSubSub(subSubFieldPosition, subSubFieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                                                                        {(subSubFields.length > 1 && subSubFieldPosition !== (subSubFields.length - 1)) && <DownCircleOutlined onClick={() => moveSubSub(subSubFieldPosition, subSubFieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                                                                        <MinusCircleOutlined onClick={() => removeSubSub(subSubName)} style={{ marginLeft: 8 }} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            })}
                                                                                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                                                                                <Button type="dashed" onClick={() => addSubSub()} block icon={<PlusOutlined />}>
                                                                                                                    {t('trip.field.itinerary.subItems.images.add')}
                                                                                                                </Button>
                                                                                                            </Form.Item>
                                                                                                        </>
                                                                                                    )}
                                                                                                </Form.List>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <div style={{ width: 100 }}>
                                                                                            {(subFields.length > 1 && subFieldPosition !== 0) && <UpCircleOutlined onClick={() => moveSub(subFieldPosition, subFieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                                            {(subFields.length > 1 && subFieldPosition !== (subFields.length - 1)) && <DownCircleOutlined onClick={() => moveSub(subFieldPosition, subFieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                                            <MinusCircleOutlined onClick={() => removeSub(subName)} style={{ marginLeft: 8 }} />
                                                                                        </div>
                                                                                    </div>
                                                                                })}
                                                                                <Form.Item wrapperCol={{ span: 24 }}>
                                                                                    <Button type="dashed" onClick={() => addSub()} block icon={<PlusOutlined />}>
                                                                                        {t('trip.field.itinerary.subItems.add')}
                                                                                    </Button>
                                                                                </Form.Item>
                                                                            </>
                                                                        )}
                                                                    </Form.List>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8, fontSize: 16 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.itinerary.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Tabs.TabPane>
                                    })}
                                </Tabs> : <div>{t('trip.alerts.language')}</div>}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('trip.tabPane.addons')} key="addons" forceRender>
                                {addedLanguages.length > 0 ? <Tabs>
                                    {addedLanguages.map((language, index) => {
                                        return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                            <h1>{t('trip.headings.tripAddons')}</h1>
                                            <Form.List name={[language.value, "tripAddons"]}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" className="trip-addon-block">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'name']}
                                                                                label={t('trip.field.tripAddon.name.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.tripAddon.name.required') }]}
                                                                            >
                                                                                <Input placeholder={t('trip.field.tripAddon.name.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'code']}
                                                                                label={t('trip.field.tripAddon.code.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.tripAddon.code.required') }]}
                                                                            >
                                                                                <Input placeholder={t('trip.field.tripAddon.code.placeholder')} onChange={(value) => {
                                                                                    form.setFieldValue([language.value, "tripAddons", name, 'code'], value.target.value.toUpperCase());
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'type']}
                                                                                label={t('trip.field.tripAddon.type.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.tripAddon.type.required') }]}
                                                                            >
                                                                                <Select>
                                                                                    <Select.Option value="boolean">{t('trip.field.tripAddon.type.boolean')}</Select.Option>
                                                                                    {/* <Select.Option value="number">{t('trip.field.tripAddon.type.number')}</Select.Option>
                                                                                    <Select.Option value="array">{t('trip.field.tripAddon.type.array')}</Select.Option> */}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'placeholder']}
                                                                                label={t('trip.field.tripAddon.placeholder.label')}
                                                                            >
                                                                                <Input placeholder={t('trip.field.tripAddon.placeholder.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'status']}
                                                                                label={t('trip.field.tripAddon.status.label')}
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Switch />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'hidePrice']}
                                                                                valuePropName="checked"
                                                                                label={t('trip.field.tripAddon.hidePrice.label')}
                                                                            >
                                                                                <Switch />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'showIn']}
                                                                                label={t('trip.field.tripAddon.showIn.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.tripAddon.showIn.required') }]}
                                                                            >
                                                                                <Select>
                                                                                    <Select.Option value="all">{t('trip.field.tripAddon.showIn.all')}</Select.Option>
                                                                                    <Select.Option value="first">{t('trip.field.tripAddon.showIn.first')}</Select.Option>
                                                                                    <Select.Option value="second">{t('trip.field.tripAddon.showIn.second')}</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>

                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'price']}
                                                                                label={t('trip.field.tripAddon.price.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.tripAddon.price.required') }]}
                                                                            >
                                                                                <InputNumber style={{ width: '100%' }} placeholder={t('trip.field.tripAddon.price.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'unitPrice']}
                                                                                label={t('trip.field.tripAddon.unitPrice.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.tripAddon.unitPrice.required') }]}
                                                                            >
                                                                                <InputNumber style={{ width: '100%' }} placeholder={t('trip.field.tripAddon.unitPrice.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>

                                                                    </Row>
                                                                    <Form.Item
                                                                        dependencies={[[language.value, "tripAddons", name, 'type']]}
                                                                        noStyle
                                                                    >
                                                                        {() => {
                                                                            let type = form.getFieldValue([language.value, "tripAddons", name, 'type']);
                                                                            if (type === 'array') {
                                                                                return <>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                                        labelCol={{ span: 24 }}
                                                                                        wrapperCol={{ span: 24 }}
                                                                                        label={t('trip.field.tripAddon.options.label')}
                                                                                        name={[name, 'options']}>
                                                                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.tripAddon.options.placeholder')} />
                                                                                    </Form.Item>
                                                                                    <div dangerouslySetInnerHTML={{ __html: t('trip.field.tripAddon.options.instruction') }}></div>
                                                                                </>
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        }}
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                        labelCol={{ span: 24 }}
                                                                        wrapperCol={{ span: 24 }}
                                                                        name={[name, 'description']}
                                                                        label={t('trip.field.tripAddon.description.label')}
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.tripAddon.description.placeholder')} />
                                                                    </Form.Item>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8, fontSize: 16 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.tripAddon.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                            <h1>{t('trip.headings.addons')}</h1>
                                            <Form.List name={[language.value, "addons"]}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" className="trip-addon-block">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'name']}
                                                                                label={t('trip.field.addon.name.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.addon.name.required') }]}
                                                                            >
                                                                                <Input placeholder={t('trip.field.addon.name.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'code']}
                                                                                label={t('trip.field.addon.code.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.addon.code.required') }]}
                                                                            >
                                                                                <Input placeholder={t('trip.field.addon.code.placeholder')} onChange={(value) => {
                                                                                    form.setFieldValue([language.value, "addons", name, 'code'], slugify(value.target.value));
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'type']}
                                                                                label={t('trip.field.addon.type.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.addon.type.required') }]}
                                                                            >
                                                                                <Select>
                                                                                    <Select.Option value="boolean">{t('trip.field.addon.type.boolean')}</Select.Option>
                                                                                    <Select.Option value="number">{t('trip.field.addon.type.number')}</Select.Option>
                                                                                    <Select.Option value="text">{t('trip.field.addon.type.text')}</Select.Option>
                                                                                    <Select.Option value="array">{t('trip.field.addon.type.array')}</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'placeholder']}
                                                                                label={t('trip.field.addon.placeholder.label')}
                                                                            >
                                                                                <Input placeholder={t('trip.field.addon.placeholder.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'status']}
                                                                                label={t('trip.field.addon.status.label')}
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Switch />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'hidePrice']}
                                                                                valuePropName="checked"
                                                                                label={t('trip.field.addon.hidePrice.label')}
                                                                            >
                                                                                <Switch />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'showIn']}
                                                                                label={t('trip.field.addon.showIn.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.addon.showIn.required') }]}
                                                                            >
                                                                                <Select>
                                                                                    <Select.Option value="all">{t('trip.field.addon.showIn.all')}</Select.Option>
                                                                                    <Select.Option value="first">{t('trip.field.addon.showIn.first')}</Select.Option>
                                                                                    <Select.Option value="second">{t('trip.field.addon.showIn.second')}</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'price']}
                                                                                label={t('trip.field.addon.price.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.addon.price.required') }]}
                                                                            >
                                                                                <InputNumber style={{ width: '100%' }} placeholder={t('trip.field.addon.price.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>

                                                                    </Row>
                                                                    <Form.Item
                                                                        dependencies={[[language.value, "addons", name, 'type']]}
                                                                        noStyle
                                                                    >
                                                                        {() => {
                                                                            let type = form.getFieldValue([language.value, "addons", name, 'type']);
                                                                            if (type === 'array') {
                                                                                return <>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                                        labelCol={{ span: 24 }}
                                                                                        wrapperCol={{ span: 24 }}
                                                                                        label={t('trip.field.addon.options.label')}
                                                                                        name={[name, 'options']}>
                                                                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.addon.options.placeholder')} />
                                                                                    </Form.Item>
                                                                                    <div dangerouslySetInnerHTML={{ __html: t('trip.field.addon.options.instruction') }}></div>
                                                                                </>
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        }}
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                        labelCol={{ span: 24 }}
                                                                        wrapperCol={{ span: 24 }}
                                                                        name={[name, 'description']}
                                                                        label={t('trip.field.addon.description.label')}
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.addon.description.placeholder')} />
                                                                    </Form.Item>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8, fontSize: 16 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.addon.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Tabs.TabPane>
                                    })}
                                </Tabs> : <div>{t('trip.alerts.language')}</div>}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('trip.tabPane.remarks')} key="remarks" forceRender>
                                {addedLanguages.length > 0 ? <Tabs>
                                    {addedLanguages.map((language, index) => {
                                        return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                            <h1>{t('trip.headings.remarks')}</h1>
                                            <Form.List name={[language.value, 'remarks']}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" className="trip-remark-block">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'title']}
                                                                                label={t('trip.field.remarks.title.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.remarks.title.required') }]}
                                                                            >
                                                                                <Input placeholder={t('trip.field.remarks.title.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                name={[name, 'type']}
                                                                                label={t('trip.field.remarks.type.label')}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.remarks.type.required') }]}
                                                                            >
                                                                                <Select>
                                                                                    <Select.Option value="product">Product</Select.Option>
                                                                                    <Select.Option value="checkout">Checkout</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <Form.List name={[name, "content"]} style={{ width: '100%' }}>
                                                                                {(contentFields, { add: addContent, remove: removeContent, move: moveContent }) => (
                                                                                    <>
                                                                                        <div>{t('trip.field.remarks.content.label')}</div>
                                                                                        {contentFields.map(({ key: contentKey, name: contentName, ...restContentField }, contentFieldPosition) => {
                                                                                            return <div key={contentKey} style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }} align="baseline">
                                                                                                <div style={{ width: "calc(100% - 100px)" }}>
                                                                                                    <Row gutter={[16, 16]}>
                                                                                                        <Col span={24} style={{ display: 'none' }}>
                                                                                                            <Form.Item
                                                                                                                {...restContentField}
                                                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                                                name={[contentName]}
                                                                                                                wrapperCol={{ span: 24 }}
                                                                                                                required={true}
                                                                                                                rules={[{ required: true, message: t('trip.field.remarks.content.required') }]}
                                                                                                            >
                                                                                                                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.remarks.content.placeholder')} />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                        <Col span={24}>
                                                                                                            <Form.Item
                                                                                                                wrapperCol={{ span: 24 }}
                                                                                                                style={{ display: 'block', marginBottom: 8, width: '100%' }}
                                                                                                                shouldUpdate>
                                                                                                                {() => {
                                                                                                                    let value = form.getFieldValue([language.value, 'remarks', name, 'content', contentName]);
                                                                                                                    if (typeof value === 'undefined' || !value) value = '';
                                                                                                                    console.log('123');
                                                                                                                    return <TextEditor
                                                                                                                        value={value}
                                                                                                                        onChange={(value) => {
                                                                                                                            console.log(value);
                                                                                                                            form.setFieldValue([language.value, 'remarks', name, 'content', contentName], value)
                                                                                                                        }}
                                                                                                                        placeholder={t('trip.field.remarks.content.placeholder')}
                                                                                                                    />
                                                                                                                }}
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                                <div style={{ width: 100 }}>
                                                                                                    {(contentFields.length > 1 && contentFieldPosition !== 0) && <UpCircleOutlined onClick={() => moveContent(contentFieldPosition, contentFieldPosition - 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                                                    {(contentFields.length > 1 && contentFieldPosition !== (contentFields.length - 1)) && <DownCircleOutlined onClick={() => moveContent(contentFieldPosition, contentFieldPosition + 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                                                    <MinusCircleOutlined onClick={() => removeContent(contentName)} style={{ marginLeft: 8, fontSize: 16 }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        })}
                                                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                                                            <Button type="dashed" onClick={() => addContent()} block icon={<PlusOutlined />}>
                                                                                                {t('trip.field.remarks.content.add')}
                                                                                            </Button>
                                                                                        </Form.Item>
                                                                                    </>
                                                                                )}
                                                                            </Form.List>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.remarks.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Tabs.TabPane>
                                    })}
                                </Tabs> : <div>{t('trip.alerts.language')}</div>}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('trip.tabPane.agreements')} key="agreements" forceRender>
                                {addedLanguages.length > 0 ? <Tabs>
                                    {addedLanguages.map((language, index) => {
                                        return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                            <h1>{t('trip.headings.agreements')}</h1>
                                            <Form.List name={[language.value, 'agreements']}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" className="trip-agreement-block">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                        labelCol={{ span: 24 }}
                                                                        wrapperCol={{ span: 24 }}
                                                                        name={[name, 'title']}
                                                                        label={t('trip.field.agreements.title.label')}
                                                                        required={true}
                                                                        rules={[{ required: true, message: t('trip.field.agreements.title.required') }]}
                                                                    >
                                                                        <Input placeholder={t('trip.field.agreements.title.placeholder')} />
                                                                    </Form.Item>
                                                                    <Form.List name={[name, "content"]} style={{ width: '100%' }}>
                                                                        {(contentFields, { add: addContent, remove: removeContent, move: moveContent }) => (
                                                                            <>
                                                                                <div>{t('trip.field.agreements.content.label')}</div>
                                                                                {contentFields.map(({ key: contentKey, name: contentName, ...restContentField }, contentFieldPosition) => {
                                                                                    return <div key={contentKey} style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }} className="trip-agreement-content-block" align="baseline">
                                                                                        <div style={{ width: "calc(100% - 100px)" }}>
                                                                                            <Row gutter={[16, 16]}>
                                                                                                <Col span={12}>
                                                                                                    <Form.Item
                                                                                                        {...restContentField}
                                                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                                                        name={[contentName, 'name']}
                                                                                                        labelCol={{ span: 24 }}
                                                                                                        label={t('trip.field.agreements.name.label')}
                                                                                                        wrapperCol={{ span: 24 }}
                                                                                                        required={true}
                                                                                                        rules={[{ required: true, message: t('trip.field.agreements.name.required') }]}
                                                                                                    >
                                                                                                        <Input.TextArea placeholder={t('trip.field.agreements.name.placeholder')} />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col span={12}>
                                                                                                    <Form.Item
                                                                                                        {...restContentField}
                                                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                                                        name={[contentName, 'code']}
                                                                                                        labelCol={{ span: 24 }}
                                                                                                        wrapperCol={{ span: 24 }}
                                                                                                        label={t('trip.field.agreements.code.label')}
                                                                                                        required={true}
                                                                                                        rules={[{ required: true, message: t('trip.field.agreements.code.required') }]}
                                                                                                    >
                                                                                                        <Input placeholder={t('trip.field.agreements.code.placeholder')} />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row gutter={[16, 16]}>
                                                                                                <Col span={12}>
                                                                                                    <Form.Item
                                                                                                        {...restContentField}
                                                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                                                        name={[contentName, 'type']}
                                                                                                        labelCol={{ span: 24 }}
                                                                                                        wrapperCol={{ span: 24 }}
                                                                                                        label={t('trip.field.agreements.type.label')}
                                                                                                        required={true}
                                                                                                        rules={[{ required: true, message: t('trip.field.agreements.type.required') }]}
                                                                                                    >
                                                                                                        <Select placeholder={t('trip.field.agreements.type.placeholder')}>
                                                                                                            <Select.Option value="boolean">{t('trip.field.agreements.type.boolean')}</Select.Option>
                                                                                                            <Select.Option value="input">{t('trip.field.agreements.type.input')}</Select.Option>
                                                                                                        </Select>
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col span={12}>
                                                                                                    <Form.Item
                                                                                                        {...restContentField}
                                                                                                        style={{ marginBottom: 8, width: '100%' }}
                                                                                                        name={[contentName, 'placeholder']}
                                                                                                        labelCol={{ span: 24 }}
                                                                                                        label={t('trip.field.agreements.placeholder.label')}
                                                                                                        wrapperCol={{ span: 24 }}
                                                                                                    >
                                                                                                        <Input placeholder={t('trip.field.agreements.placeholder.placeholder')} />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                        <div style={{ width: 100 }}>
                                                                                            {(contentFields.length > 1 && contentFieldPosition !== 0) && <UpCircleOutlined onClick={() => moveContent(contentFieldPosition, contentFieldPosition - 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                                            {(contentFields.length > 1 && contentFieldPosition !== (contentFields.length - 1)) && <DownCircleOutlined onClick={() => moveContent(contentFieldPosition, contentFieldPosition + 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                                                                            <MinusCircleOutlined onClick={() => removeContent(contentName)} style={{ marginLeft: 8, fontSize: 16 }} />
                                                                                        </div>
                                                                                    </div>
                                                                                })}
                                                                                <Form.Item wrapperCol={{ span: 24 }}>
                                                                                    <Button type="dashed" onClick={() => addContent()} block icon={<PlusOutlined />}>
                                                                                        {t('trip.field.agreements.content.add')}
                                                                                    </Button>
                                                                                </Form.Item>
                                                                            </>
                                                                        )}
                                                                    </Form.List>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.agreements.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Tabs.TabPane>
                                    })}
                                </Tabs> : <div>{t('trip.alerts.language')}</div>}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('trip.tabPane.statement')} key="statement" forceRender>
                                {addedLanguages.length > 0 ? <Tabs>
                                    {addedLanguages.map((language, index) => {
                                        return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                            <>
                                                <Form.Item
                                                    label={t('trip.field.statement.display.label')}
                                                    name={[language.value, 'statement', 'display']}
                                                    valuePropName="checked"
                                                >
                                                    <Switch />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[language.value, 'statement', 'title']}
                                                    label={t('trip.field.statement.title.label')}>
                                                    <Input placeholder={t('trip.field.statement.title.placeholder')} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ display: 'none' }}
                                                    name={[language.value, 'statement', 'content']}
                                                    label={t('trip.field.statement.content.label')}
                                                    placeholder={t('trip.field.statement.content.placeholder')}>
                                                    <Input.TextArea autoSize={{ minRows: 10, maxRows: 20 }} />
                                                </Form.Item>
                                                <Form.Item
                                                    label={t('trip.field.statement.content.label')}
                                                    shouldUpdate>
                                                    {() => {
                                                        let value = form.getFieldValue([language.value, 'statement', 'content']);
                                                        if (typeof value === 'undefined' || !value) value = '';
                                                        return <TextEditor
                                                            value={value}
                                                            onChange={(value) => {
                                                                form.setFieldValue([language.value, 'statement', 'content'], value);
                                                            }}
                                                            placeholder={t('trip.field.statement.content.placeholder')}
                                                        />
                                                    }}
                                                </Form.Item>
                                                <Form.List name={[language.value, "statement", "items"]} style={{ width: '100%' }}>
                                                    {(fields, { add, remove, move }) => (
                                                        <>
                                                            <h1>{t('trip.field.statement.items.label')}</h1>
                                                            {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                                return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                    <div style={{ width: 'calc(100% - 100px)' }}>
                                                                        <Row gutter={[16, 16]}>
                                                                            <Col span={12}>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    style={{ marginBottom: 8, width: '100%' }}
                                                                                    name={[name, 'type']}
                                                                                    labelCol={{ span: 24 }}
                                                                                    wrapperCol={{ span: 24 }}
                                                                                >
                                                                                    <Select placeholder={t('trip.field.statement.items.type.placeholder')}>
                                                                                        <Select.Option value="boolean">{t('trip.field.statement.items.type.boolean')}</Select.Option>
                                                                                        <Select.Option value="input">{t('trip.field.statement.items.type.input')}</Select.Option>
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    style={{ marginBottom: 8, width: '100%' }}
                                                                                    name={[name, 'code']}
                                                                                    labelCol={{ span: 24 }}
                                                                                    wrapperCol={{ span: 24 }}
                                                                                    required={true}
                                                                                    rules={[{ required: true, message: t('trip.field.statement.items.code.required') }]}
                                                                                >
                                                                                    <Input placeholder={t('trip.field.statement.items.code.placeholder')} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    style={{ marginBottom: 8, width: '100%' }}
                                                                                    name={[name, 'name']}
                                                                                    labelCol={{ span: 24 }}
                                                                                    wrapperCol={{ span: 24 }}
                                                                                    required={true}
                                                                                    rules={[{ required: true, message: t('trip.field.statement.items.name.required') }]}
                                                                                >
                                                                                    <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('trip.field.statement.items.name.placeholder')} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    style={{ marginBottom: 8, width: '100%' }}
                                                                                    name={[name, 'placeholder']}
                                                                                    labelCol={{ span: 24 }}
                                                                                    wrapperCol={{ span: 24 }}
                                                                                >
                                                                                    <Input placeholder={t('trip.field.statement.items.placeholder.placeholder')} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div style={{ width: 100 }}>
                                                                        {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                        {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                                    </div>
                                                                </div>
                                                            })}
                                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                    {t('trip.field.documents.add')}
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </>
                                        </Tabs.TabPane>
                                    })}
                                </Tabs> : <div>{t('trip.alerts.language')}</div>}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('trip.tabPane.documents')} key="documents" forceRender>
                                {addedLanguages.length > 0 ? <Tabs>
                                    {addedLanguages.map((language, index) => {
                                        return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                            <Form.List name={[language.value, "documents"]} style={{ width: '100%' }}>
                                                {(fields, { add, remove, move }) => (
                                                    <>
                                                        <h1>{t('trip.field.documents.label')}</h1>
                                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                <div style={{ width: 'calc(100% - 100px)' }}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                name={[name, 'type']}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                                required={true}
                                                                                rules={[{ required: true, message: t('trip.field.documents.type.required') }]}
                                                                            >
                                                                                <Select placeholder={t('trip.field.documents.type.placeholder')}>
                                                                                    <Select.Option value="media">{t('trip.field.documents.type.media')}</Select.Option>
                                                                                    <Select.Option value="link">{t('trip.field.documents.type.link')}</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{ marginBottom: 8, width: '100%' }}
                                                                                name={[name, 'name']}
                                                                                labelCol={{ span: 24 }}
                                                                                wrapperCol={{ span: 24 }}
                                                                            >
                                                                                <Input placeholder={t('trip.field.documents.name.placeholder')} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                                                wrapperCol: 16,
                                                                                required: true,
                                                                                style: { marginBottom: 8, width: 'calc(100% - 100px)' }
                                                                            }} inner={{
                                                                                name: [name, 'value'],
                                                                                rules: [
                                                                                    {
                                                                                        required: true,
                                                                                        message: t('trip.field.documents.required')
                                                                                    }
                                                                                ]
                                                                            }}
                                                                                fieldName={[language.value, "documents", name, "value"]}
                                                                                placeholder={t('trip.field.documents.placeholder')} />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div style={{ width: 100 }}>
                                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <Form.Item wrapperCol={{ span: 24 }}>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {t('trip.field.documents.add')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Tabs.TabPane>
                                    })}
                                </Tabs> : <div>{t('trip.alerts.language')}</div>}
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                    <Drawer contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }} title={t('mediaDrawer.title')} placement="right" onClose={onMediaDrawerClose} open={mediaDrawerVisible}>
                        <EntityMedia entityId={params.entityId} selectMedia={onSelectMedia} />
                    </Drawer>
                </div>
            </Content >
            <MemberFooter />
        </Layout >
    </>;
}

export default Trip;